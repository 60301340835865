<template>
    <nav class="navbar navbar-main navbar-expand-lg navbar-dark bg-primary navbar-border" id="navbar-main">
        <div class="container-fluid">
            <!-- Brand + Toggler (For Mobile) -->
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-main-collapse" aria-controls="navbar-main-collapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <!-- Navbar actions (For Mobile) -->
            <div class="navbar-user d-lg-none ml-auto">
                <ul class="navbar-nav flex-row align-items-center">
                    <li class="nav-item" style="color: white;"><a @click="logout">登出</a></li>

                    <li class="nav-item"   v-if="false">
                        <a href="#" class="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main"><i class="fas fa-bars"></i></a>
                    </li>
                    <li class="nav-item dropdown dropdown-animate"  v-if="false"> 
                        <a class="nav-link nav-link-icon" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-bell"></i></a>
                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg dropdown-menu-arrow p-0">
                            <div class="py-3 px-3">
                                <h5 class="heading h6 mb-0">通知訊息</h5>
                            </div>
                            <div class="list-group list-group-flush">
                                <a href="#" class="list-group-item list-group-item-action">
                                    <div class="d-flex align-items-center" data-toggle="tooltip" data-placement="right" data-title="2 hrs ago">
                                        <div>
                                            <span class="avatar bg-primary text-white rounded-circle">AM</span>
                                        </div>
                                        <div class="flex-fill ml-3">
                                            <div class="h6 text-sm mb-0">Alex Michael <small class="float-right text-muted">2 hrs ago</small></div>
                                            <p class="text-sm lh-140 mb-0">
                                                Some quick example text to build on the card title.
                                            </p>
                                        </div>
                                    </div>
                                </a>
                                <a href="#" class="list-group-item list-group-item-action">
                                    <div class="d-flex align-items-center" data-toggle="tooltip" data-placement="right" data-title="3 hrs ago">
                                        <div>
                                            <span class="avatar bg-warning text-white rounded-circle">SW</span>
                                        </div>
                                        <div class="flex-fill ml-3">
                                            <div class="h6 text-sm mb-0">Sandra Wayne <small class="float-right text-muted">3 hrs ago</small></div>
                                            <p class="text-sm lh-140 mb-0">
                                                Some quick example text to build on the card title.
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="py-3 text-center">
                                <a href="#" class="link link-sm link--style-3">查看全部通知訊息</a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <!-- Navbar nav -->
            <div class="collapse navbar-collapse navbar-collapse-fade" id="navbar-main-collapse">
                <ul class="navbar-nav align-items-lg-center" style="flex-wrap: wrap;">
                    <li class="nav-item" style="word-break: keep-all;" v-for="(item, index) in userMenu" :key="item.name + index" @click="toPath(item.path)">
                        <a class="nav-link"  role="button" aria-haspopup="true" aria-expanded="false">{{item.name}}</a>
                    </li>
                </ul>
                <!-- Right action -->
                <ul class="navbar-nav ml-lg-auto align-items-center d-none d-md-flex" >
                    <li class="nav-item">
                        <a href="#" class="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main"><i class="fas fa-bars"></i></a>
                    </li>
                    <li class="nav-item dropdown dropdown-animate" v-if="false">
                        <a class="nav-link nav-link-icon" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="false"><i class="fas fa-bell"></i></a>
                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg dropdown-menu-arrow p-0">
                            <div class="py-3 px-3">
                                <h5 class="heading h6 mb-0">通知訊息</h5>
                            </div>
                            <div class="list-group list-group-flush">
                                <a href="#" class="list-group-item list-group-item-action">
                                    <div class="d-flex align-items-center" data-toggle="tooltip" data-placement="right" data-title="2 hrs ago">
                                        <div>
                                            <span class="avatar bg-primary text-white rounded-circle">AM</span>
                                        </div>
                                        <div class="flex-fill ml-3">
                                            <div class="h6 text-sm mb-0">Alex Michael <small class="float-right text-muted">2 hrs ago</small></div>
                                            <p class="text-sm lh-140 mb-0">
                                                Some quick example text to build on the card title.
                                            </p>
                                        </div>
                                    </div>
                                </a>
                                <a href="#" class="list-group-item list-group-item-action">
                                    <div class="d-flex align-items-center" data-toggle="tooltip" data-placement="right" data-title="3 hrs ago">
                                        <div>
                                            <span class="avatar bg-warning text-white rounded-circle">SW</span>
                                        </div>
                                        <div class="flex-fill ml-3">
                                            <div class="h6 text-sm mb-0">Sandra Wayne <small class="float-right text-muted">3 hrs ago</small></div>
                                            <p class="text-sm lh-140 mb-0">
                                                Some quick example text to build on the card title.
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="py-3 text-center">
                                <a href="#" class="link link-sm link--style-3">查看所有通知訊息</a>
                            </div>
                        </div>
                    </li>
                    <li style="color: white;word-break: keep-all;"><a @click="logout">登出</a></li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import commonMixin from '@/utils/commonMixin';

export default {
    name: "mainNav",
    mixins: [commonMixin],
    mounted(){
        var vm = this;
        vm.userMenu = vm.menuData.filter(function(item) {
            if (item.name === '首頁') 
                return true;
            
            if (vm.loginAuth.role === "S" && item.name === "事業單位基本資料維護")
                return false;

            return vm.loginAuth.userTree.childNodeList.some(function (uItem){
                return uItem.nodeName === item.name;
            });
        });

        if (vm.loginAuth.role === "O") {
            vm.userMenu = vm.userMenu.map(function (item) {
                if (item.name === "事業單位基本資料維護")
                    item.path = item.path + "/" + vm.loginAuth.orgnization.orgId;
                return item;
            });
        }
    },
    methods:{
        toPath: function(pathStr) {
            if (this.$route.path !== pathStr) {
                this.$router.push({ path: pathStr });
            }
        },
    },
    data: () => ({
        userMenu: [],
        menuData:[
            {
                name: "首頁",
                path: "/index",
                class: "fas fa-home fa-2x"
            },
            {
                name: "事業單位基本資料設定",
                path: "/orgnization/orgnizationList",
                class: "fas fa-project-diagram fa-2x",
            },
            {
                name: "事業單位基本資料維護",
                path: "/orgnization/orgnizationEdit",
                class: "fas fa-project-diagram fa-2x",
            },
            {
                name: "場域管理者帳號設定",
                path: "/member/jsMemberList",
                class: "fas fa-tasks fa-2x",
            },
            {
                name: "事業單位管理者帳號設定",
                path: "/member/memberList",
                class: "fas fa-columns fa-2x",
            },
            {
                name: "人員進出自動辨識",
                path: "/inout/inoutList",
                class: "fas fa-users-cog fa-2x",
            },
            {
                name: "事業單位人員維護",
                path: "/orgnizationMember/orgnizationMemberList",
                class: "fas fa-user-ninja fa-2x",
            },
            {
                name: "場域設定及管理",
                path: "/jobSite/jobSiteList",
                class: "fas fa-receipt fa-2x",
            },
            {
                name: "入出紀錄清單",
                path: '/inoutRecord/inoutRecord',
                class: "fas fa-project-diagram fa-2x",
            },
            {
                name: "辨識調校清單",
                path: '/inoutRecord/inoutDetectRecordList',
                class: "fas fa-project-diagram fa-2x",
            },
            {
                name: "參數維護",
                path: '/parameter/paraList/root',
                class: "fas fa-cogs fa-2x",
            },
        ],
    })
};
</script>