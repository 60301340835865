<template>
    <!--left-->
    <div class="sidenav" id="sidenav-main" style="z-index:5;">
        <!-- Sidenav header -->
        <div class="sidenav-header d-flex align-items-center">
            <a class="navbar-brand" href="/hyireco/index">
                <img src="/hyireco/images/logo2.png" class="navbar-brand-img" alt="HYiReco">
            </a>
            <div class="ml-auto">
                <!-- Sidenav toggler -->
                <div class="sidenav-toggler sidenav-toggler-dark d-md-none" data-action="sidenav-unpin" data-target="#sidenav-main">
                    <div class="sidenav-toggler-inner">
                        <i class="sidenav-toggler-line bg-white"></i>
                        <i class="sidenav-toggler-line bg-white"></i>
                        <i class="sidenav-toggler-line bg-white"></i>
                    </div>
                </div>
            </div>
        </div>
        <!-- User mini profile -->
        <div class="sidenav-user d-flex flex-column align-items-center justify-content-between text-center">
            <!-- Avatar -->
            <div>
                <div class="mt-6">
                    <h5 class="mb-0 text-white">{{userName}}</h5>
                    <span class="d-block text-sm text-white opacity-8 mb-3">祝你有個美好的一天!!</span>
                    <a class="btn btn-sm btn-white btn-icon rounded-pill shadow hover-translate-y-n3" @click="toEditData" v-if="loginAuth.role !== 'S'">
                        <span class="btn-inner--icon" ><i class="fas fa-edit"></i></span>
                        <span class="btn-inner--text" >編輯基本資料</span>
                    </a>
                </div>
            </div>
            <!-- Actions -->
            <div class="w-100 mt-4 actions d-flex justify-content-between">
                <a href="#" class="action-item action-item-lg text-white pl-0" v-if="false">
                    <i class="fas fa-user"></i>
                </a>
                <a href="#" class="action-item action-item-lg text-white"  v-if="false">
                    <i class="fas fa-comment-alt"></i>
                </a>
                <a href="#" class="action-item action-item-lg text-white pr-0" v-if="false">
                    <i class="fas fa-receipt"></i>
                </a>

                <i></i>
                <i><v-icon>mdi-emoticon-outline</v-icon></i>
                <i></i>
            </div>
        </div>
        <!-- Application nav -->
        <div class="nav-application clearfix">
            <a v-for="(item, index) in userMenu" :class="isActivePage(item.path)" :key="item.name + index" @click="toPath(item.path)">
                <span class="btn-inner--icon d-block"><i :class="item.class"></i></span>
                <span class="btn-inner--icon d-block pt-2">{{item.name}}</span>
            </a>
        </div>
    </div>
    <!--left-->
</template>

<script>
import commonMixin from '@/utils/commonMixin';

export default {
    name: "mainMenu",
    mixins: [commonMixin],
    mounted(){
        var vm = this;
        //console.log("vm.loginAuth.userTree.childNodeList = " + JSON.stringify(vm.loginAuth.userTree.childNodeList));
        vm.userMenu = vm.menuData.filter(function(item) {
            if (item.name === '首頁') 
                return true;
            
            if (vm.loginAuth.role === "S" && item.name === "事業單位基本資料維護")
                return false;

            return vm.loginAuth.userTree.childNodeList.some(function (uItem){
                return uItem.nodeName === item.name;
            });
        });

        if (vm.loginAuth.role === "O") {
            vm.userMenu = vm.userMenu.map(function (item) {
                if (item.name === "事業單位基本資料維護")
                    item.path = item.path + "/" + vm.loginAuth.orgnization.orgId;
                return item;
            });
        }

        this.userName = this.loginAuth.memberName;
    },
    methods: {
        toPath: function(pathStr) {
            window.scrollTo(0,0);
            if (this.$route.path !== pathStr) {
                this.$router.push({ path: pathStr });
            }
        },
        isActivePage: function(path){
           return this.$route.path === path ? "btn btn-square text-sm active" : "btn btn-square text-sm"; 
        },
        toEditData: function() {
            var vm = this;
            var pathStr = "";
            if (vm.loginAuth.role === "O") {
                pathStr = "/member/memberEdit/" + vm.loginAuth.uuid;
            } else if (vm.loginAuth.role === "J") {
                pathStr = "/member/jsMemberEdit/" + vm.loginAuth.uuid;
            }

            this.$router.push({ path: pathStr });
        }
    },
    data: () => ({
        userName: "",
        userMenu: [],
        menuData:[
            {
                name: "首頁",
                path: "/index",
                class: "fas fa-home fa-2x"
            },
            {
                name: "事業單位基本資料設定",
                path: "/orgnization/orgnizationList",
                class: "fas fa-project-diagram fa-2x",
            },
            {
                name: "事業單位基本資料維護",
                path: "/orgnization/orgnizationEdit",
                class: "fas fa-project-diagram fa-2x",
            },
            {
                name: "場域管理者帳號設定",
                path: "/member/jsMemberList",
                class: "fas fa-tasks fa-2x",
            },
            {
                name: "事業單位管理者帳號設定",
                path: "/member/memberList",
                class: "fas fa-columns fa-2x",
            },
            {
                name: "人員進出自動辨識",
                path: "/inout/inoutList",
                class: "fas fa-users-cog fa-2x",
            },
            {
                name: "事業單位人員維護",
                path: "/orgnizationMember/orgnizationMemberList",
                class: "fas fa-user-ninja fa-2x",
            },
            {
                name: "場域設定及管理",
                path: "/jobSite/jobSiteList",
                class: "fas fa-receipt fa-2x",
            },
            {
                name: "參數維護",
                path: '/parameter/paraList/root',
                class: "fas fa-cogs fa-2x",
            },
            {
                name: "辨識調校清單",
                path: '/inoutRecord/inoutDetectRecordList',
                class: "fas fa-project-diagram fa-2x",
            },
            {
                name: "入出紀錄清單",
                path: '/inoutRecord/inoutRecord',
                class: "fas fa-project-diagram fa-2x",
            },
        ],
        activePage: "",
    })
};
</script>