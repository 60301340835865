<template>
    <div class="footer pt-5 pb-4 footer-light" id="footer-main">
        <div class="row text-center text-sm-left align-items-sm-center">
            <div class="col-sm-6">
                <p class="text-sm mb-0">&copy; 2021 Copyright 凌網科技股份有限公司版權所有</p>
            </div>
            <div class="col-sm-6 mb-md-0" v-if="false">
                <ul class="nav justify-content-center justify-content-md-end">
                    <li class="nav-item">
                        <a class="nav-link" href="#">隱私權政策及網站安全政策</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">幫助</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link pr-0" href="#">關於我們</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "mainFooter",

  data: () => ({})
};
</script>